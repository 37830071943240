
  .intrested_contact .redirect_contact {
      padding: 0.75rem 1.5rem;
      margin: 1.25rem 0;
      color: red;
      font-weight: 400;
      font-size: 1.15rem;
      background: red;
      display: inline-block;
      border: 2px solid #4abc55;
    }
    .intrested_contact .redirect_contact:hover {
      background: #4abc55;
      color: #fff;
      border: 2px solid #4abc55;
    }
    .intrested_contact p {
      text-align: center !important;
      font-size: 1.85rem;
      font-weight: 500;
      margin-bottom: 15px;
      color: #0f0909;
    }
  